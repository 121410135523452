<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <div class="p-d-flex p-ai-center heading">
    <div class="p-mr-1">
      <h1>Roles & Permissions</h1>
    </div>
    <div>
      <Button
        v-if="$store.getters.isSuper"
        label="Create"
        icon="pi pi-plus"
        class="p-button-rounded p-button-text p-button-sm"
        @click="$router.push({ name: 'PermissionCreate' })"
      />
    </div>
  </div>
  <DataTable :value="permissions" class="p-datatable-responsive">
    <Column v-for="column in columns" :key="column" :header="column">
      <template #body="slotProps">
        <span class="p-column-title">{{ column }}</span>
        <span v-if="column">
          <PermissionCheckbox
            :role="column"
            :roles="slotProps.data.roles"
            :permissionId="slotProps.data.id"
            :disabled="disabled"
            @submitted="savePermission"
          />
        </span>
        <strong v-else>{{ slotProps.data.name }}</strong>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast.js'
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import PermissionCheckbox from '@/components/PermissionCheckbox'
import { db } from '@/services/firebase'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { DataTable, Column, PermissionCheckbox, Button, BaseBreadcrumb },
  setup() {
    const store = useStore()
    const toast = useToast()
    const permissions = ref([])
    const disabled = ref(false)
    const columns = [
      '',
      'Booking Admin',
      'Client Admin',
      'Medical Admin',
      'Testing Admin',
      'Tester',
      'Super'
    ]
    const breadcrumbs = [{ label: 'Permissions' }]

    watchEffect(() => {
      permissions.value = store.state.permissions.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    })

    function savePermission(data) {
      disabled.value = true
      let roles = data.roles

      if (data.value === true) {
        // if checked, add role
        if (!roles.includes(data.role)) roles.push(data.role)
      } else {
        // if unchecked, remove role
        roles = roles.filter(row => {
          return row !== data.role
        })
      }

      if (roles.length) {
        // save, if at least one role
        db.ref('permissions/' + data.permissionId)
          .update({ roles })
          .then(() => {
            store.dispatch('getPermissions').then(() => {
              disabled.value = false
            })
          })
      } else {
        // throw error that one role must be selected
        store.dispatch('getPermissions').then(() => {
          disabled.value = false
          toast.add({
            severity: 'error',
            summary: 'Login Failed',
            detail: 'Feature must have at least one role',
            life: 3000
          })
        })
      }
    }

    return { permissions, disabled, columns, savePermission, breadcrumbs }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
</style>
