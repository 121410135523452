<template>
  <Checkbox v-model="checked" :binary="true" :disabled="disabled" />
</template>

<script>
import { ref, watch, watchEffect } from 'vue'
import Checkbox from 'primevue/checkbox'
export default {
  components: { Checkbox },
  props: {
    role: {
      type: String,
      required: true
    },
    roles: {
      type: Array,
      default() {
        return []
      }
    },
    permissionId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const checked = ref(false)

    watchEffect(() => {
      if (props.roles.includes(props.role)) {
        checked.value = true
      }
    })

    watch(
      () => checked.value,
      newVal => {
        emit('submitted', {
          role: props.role,
          roles: props.roles,
          permissionId: props.permissionId,
          value: newVal
        })
      }
    )

    return { checked }
  }
}
</script>
