
import { defineComponent } from 'vue'
import Breadcrumb from 'primevue/breadcrumb'

export default defineComponent({
  components: { Breadcrumb },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const home = { icon: 'pi pi-home', to: '/' }

    return { home }
  }
})
